import React from "react";
import "pages/BusinessCampaign/BusinessCampaign.scss";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { getQueries } from "api/wagtailAdapter";
import { useLocation, useParams } from "react-router-dom";

import BannerComponent from "components/BusinessCampaign/BannerComponent";
import HighlightComponent from "components/BusinessCampaign/HighlightComponent";
import ImageDescriptionTwoColumnComponent from "components/BusinessCampaign/ImageDescriptionTwoColumnComponent";
import LogoComponent from "components/BusinessCampaign/LogoComponent";
import CarouselComponent from "components/BusinessCampaign/CarouselComponent";
import TextTitleComponent from "components/BusinessCampaign/TextTitleComponent";
import TwoColumnMediaComponent from "components/BusinessCampaign/TwoColumnMediaComponent";
import HeaderComponent from "components/BusinessCampaign/HeaderComponent";
import FooterComponent from "components/BusinessCampaign/FooterComponent";


const components_mapping = {
  banner_component: BannerComponent,
  highlight_component: HighlightComponent,
  logo_component: LogoComponent,
  image_description_two_column_component: ImageDescriptionTwoColumnComponent,
  carousel_component: CarouselComponent,
  text_title_component: TextTitleComponent,
  two_column_media_component: TwoColumnMediaComponent,
  footer_component: FooterComponent,
  header_component: HeaderComponent
};

const BusinessCampaign = () => {
  let slug = useParams().slug;
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetBusinessCampaignPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
      slug: slug
    })
  );

  const colours = {
    primary_colour: info?.primary_colour,
    secondary_colour: info?.secondary_colour,
    text_colour: info?.text_colour,
    highlight_text_colour: info?.highlight_text_colour,
    banner_text_colour: info?.banner_text_colour
  };

  return (
    <>
      {info?.components && info.components.map((component, index) => {
        console.log(component);
        return (
          <div className="component-wrapper" key={index}>
            {React.createElement(components_mapping[component.type], {
              ...component?.value,
              ...colours,
            })}
          </div>
        );
      })}
    </>
  );
};

export default BusinessCampaign;