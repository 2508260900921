import React, {useEffect, useState} from "react";
import "components/BusinessCampaign/BannerComponent.scss";
import { processWagtailImage } from "components/Wagtail/WagtailImage";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import EmailValidation from "utils/EmailValidation";
import Checkbox from "components/reusable/Checkbox/Checkbox";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "components/reusable/Button/Button";
import { useSubmitCampaignContactFormMutation } from "api/beanfield/ContactForm";
import useWindowDimensions from "utils/WindowWidth";
import { getUTMVariables } from "utils/utm";
import ClipLoader from "react-spinners/ClipLoader";

const BannerComponent = ({
    form,
    image,
    main_text,
    secondary_text,
    primary_colour,
    secondary_colour,
    text_colour,
    highlight_text_colour,
    banner_text_colour
  }) => {
  const [submitForm] = useSubmitCampaignContactFormMutation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [serviceAddress, setServiceAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");
  const [subscribe, setSubscribe] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [companyTouched, setCompanyTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [messageTouched, setMessageTouched] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = React.createRef();
  const { width } = useWindowDimensions();

  const formIsValid = () => {
    return ((firstNameTouched && lastNameTouched && emailTouched && companyTouched && phoneTouched && messageTouched && token) &&
      (!firstNameError && !lastNameError && !emailError && !companyError && !phoneError && !messageError) &&
      (emailValid));
  };

  const handleMessage = () => {
    if (formIsValid()) {
      setIsLoading(true);
      submitForm({
        firstName: firstName,
        lastName: lastName,
        company: company,
        email: email,
        phone: phone,
        serviceAddress: serviceAddress,
        message: message,
        token: token,
        subscribe: subscribe,
        utms: getUTMVariables()
      }).then(data => {
        setIsLoading(false);
        if (data?.data.error) {
          setSubmitError(true);
        }
        setMessageSent(true);
      });
    }
  };

  const recaptchaClicked = () => {
    const token = recaptchaRef.current.getValue();
    setToken(token);
  };

  const validateField = (field) => {
    switch (field) {
      case "firstName":
        setFirstNameTouched(true);
        if (firstName === "") {
          setFirstNameError(true);
        }
        else {
          setFirstNameError(false);
        }
        break;
      case 'lastName':
        setLastNameTouched(true);
        if (lastName === "") {
          setLastNameError(true);
        }
        else {
          setLastNameError(false);
        }
        break;
      case 'email':
        setEmailTouched(true);
        if (email === "") {
          setEmailError(true);
        }
        else if (!EmailValidation(email)) {
          setEmailValid(false);
          setEmailError(false);
        }
        else {
          setEmailValid(true);
          setEmailError(false);
        }
        break;
      case 'phone':
        setPhoneTouched(true);
        if (phone === "") {
          setPhoneError(true);
        }
        else {
          setPhoneError(false);
        }
        break;
      case 'company':
        setCompanyTouched(true);
        if (company === "") {
          setCompanyError(true);
        }
        else {
          setCompanyError(false);
        }
        break;
      case 'message':
        setMessageTouched(true);
        if (message === "") {
          setMessageError(true);
        }
        else {
          setMessageError(false);
        }
        break;
      default:
        return;
    }
  };

  const formContent = () => {
    return (
      <>
        <div id="campaign-form" className={`form-component-container 
          ${(messageSent && !submitError) ? 'form-success': ''}
          ${(messageSent && submitError) ? 'form-error': ''}`} style={{backgroundColor: primary_colour}}>
          {isLoading ? (
            <div className="submit-loading">
              <ClipLoader
                color={"#336600"}
                size={50}
                aria-label="Loading Spinner"
              />
            </div>
          ) : (
            <>
              {messageSent ? (
                <>
                  {submitError ? (
                    <div className="form-error-container" tabIndex="0">
                      <div className="title">
                        {convertHtmlToReact(form?.error_message_title_text)}
                      </div>
                      <div className="text">
                        {convertHtmlToReact(form?.error_message_text)}
                      </div>
                    </div>
                  ) : (
                    <div className="form-success-container" tabIndex="0">
                      <div className="title">
                        {convertHtmlToReact(form?.success_message_title_text)}
                      </div>
                      <div className="text">
                        {convertHtmlToReact(form?.success_message_text)}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="form-header" tabIndex="0">
                    <div className="form-title Wbold">
                      {convertHtmlToReact(form?.title_text)}
                    </div>
                    <div className="form-subtitle">
                      {convertHtmlToReact(form?.subtitle_text)}
                    </div>
                  </div>
                  <div className="contact-form">
                    <div className="contact-split-container">
                      <div className="input-container">
                        <label htmlFor="firstName">{form?.first_name_text}</label>
                        <input
                          id="firstName"
                          onChange={(e) => setFirstName(e.target.value)}
                          onBlur={() => {validateField("firstName")}}
                          value={firstName}
                          aria-errormessage={firstNameError}
                          aria-label={form?.form_mandatory_fn}
                        />
                        {firstNameError ? (
                          <p className="contact-error-text" tabIndex="0">
                            {form?.form_error_fn}
                          </p>
                        ) : null}
                      </div>
                      <div className="input-container">
                        <label htmlFor="lastName">{form?.last_name_text}</label>
                        <input
                          id="lastName"
                          onChange={(e) => setLastName(e.target.value)}
                          onBlur={() => {validateField("lastName")}}
                          value={lastName}
                          aria-errormessage={lastNameError}
                          aria-label={form?.form_mandatory_ln}
                        />
                        {lastNameError ? (
                          <p className="contact-error-text" tabIndex="0">
                            {form?.form_error_ln}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="contact-split-container">
                      <div className="input-container">
                        <label htmlFor="company">{form?.company_text}</label>
                        <input
                          id="company"
                          onChange={(e) => setCompany(e.target.value)}
                          onBlur={() => {validateField("company")}}
                          value={company}
                          aria-errormessage={companyError}
                          aria-label={form?.form_mandatory_company}
                        />
                        {phoneError ? (
                          <p className="contact-error-text" tabIndex="0">
                            {form?.form_error_company}
                          </p>
                        ) : null}
                      </div>
                      <div className="input-container">
                        <label htmlFor="serviceAddress">{form?.service_address_text}</label>
                        <input
                          id="serviceAddress"
                          onChange={(e) => setServiceAddress(e.target.value)}
                          value={serviceAddress}
                        />
                      </div>
                    </div>
                    <div className="contact-split-container">
                      <div className="input-container">
                        <label htmlFor="phone">{form?.phone_text}</label>
                        <input
                          id="phone"
                          onChange={(e) => setPhone(e.target.value)}
                          onBlur={() => {validateField("phone")}}
                          value={phone}
                          aria-errormessage={phoneError}
                          aria-label={form?.form_mandatory_phone}
                        />
                        {phoneError ? (
                          <p className="contact-error-text" tabIndex="0">
                            {form?.form_error_phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="input-container">
                        <label htmlFor="email">{form?.email_text}</label>
                        <input
                          id="email"
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={() => {validateField("email")}}
                          value={email}
                          aria-errormessage={emailError || !emailValid}
                          aria-label={form?.form_mandatory_email}
                        />
                        {!emailValid ? (
                          <p className="contact-error-text" tabIndex="0">
                            {form?.form_error_valid_email}
                          </p>
                        ) : emailError ? (
                          <p className="contact-error-text" tabIndex="0">
                            {form?.form_error_empty_email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="contact-container">
                      <label htmlFor="message">{form?.message_text}</label>
                      <textarea
                        id="message"
                        rows="6"
                        onChange={(e) => setMessage(e.target.value)}
                        onBlur={() => {validateField("message")}}
                        value={message}
                        aria-errormessage={messageError}
                        aria-label={form?.form_mandatory_message}
                      />
                      {messageError ? (
                        <p className="contact-error-text" tabIndex="0">
                          {form?.form_error_message}
                        </p>
                      ) : null}
                    </div>
                    {
                      width > 1024
                        ?
                        (
                          <div className="contact-split-container">
                            {(form?.subscribe_text || form?.subscribe_extra_text)
                              ? (
                                <>
                                  <div className="subscribe-container">
                                    <div className="checkbox-container">
                                      <Checkbox
                                        title=""
                                        onClick={() => {
                                          setSubscribe(!subscribe);
                                        }}
                                      />
                                    </div>
                                    <div className="content-checkbox-container">
                                      <div className="bold-text">
                                        {convertHtmlToReact(form?.subscribe_text)}
                                      </div>
                                      {convertHtmlToReact(form?.subscribe_extra_text)}
                                      <Button btnSytle="campaign-button" style={{ backgroundColor: highlight_text_colour, color: banner_text_colour }} click={() => handleMessage()} disabled={!formIsValid()}>
                                        {form?.submit_text}
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="captcha-container">
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      size={width > 1024 ? "compact" : "normal"}
                                      sitekey={process.env.REACT_APP_CAMPAIGN_KEY}
                                      onChange={recaptchaClicked}
                                    />
                                  </div>
                                </>
                              )
                              : (
                                <>
                                  <div className="captcha-container">
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      size="normal"
                                      sitekey={process.env.REACT_APP_CAMPAIGN_KEY}
                                      onChange={recaptchaClicked}
                                    />
                                    <Button btnSytle="campaign-button" style={{ backgroundColor: highlight_text_colour, color: banner_text_colour }} click={() => handleMessage()} disabled={!formIsValid()}>
                                      {form?.submit_text}
                                    </Button>
                                  </div>
                                </>
                              )}

                          </div>
                        )
                        :
                        width > 768 && width <= 1024
                          ?
                          (
                            <>
                              <div className="captcha-subscribe-container">
                                {(form?.subscribe_text || form?.subscribe_extra_text)
                                  ? (
                                    <div className="subscribe-container">
                                      <div className="checkbox-container">
                                        <Checkbox
                                          title=""
                                          onClick={() => {
                                            setSubscribe(!subscribe);
                                          }}
                                        />
                                      </div>
                                      <div className="content-checkbox-container">
                                        <div className="bold-text">
                                          {convertHtmlToReact(form?.subscribe_text)}
                                        </div>
                                        {convertHtmlToReact(form?.subscribe_extra_text)}
                                      </div>
                                    </div>
                                  ) : null }
                                <div className="captcha-container">
                                  <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="normal"
                                    sitekey={process.env.REACT_APP_CAMPAIGN_KEY}
                                    onChange={recaptchaClicked}
                                  />
                                </div>
                              </div>
                              <Button btnSytle="campaign-button" style={{ backgroundColor: highlight_text_colour, color: banner_text_colour }} click={() => handleMessage()} disabled={!formIsValid()}>
                                {form?.submit_text}
                              </Button>
                            </>
                          )
                          : (
                            <>
                              <div className="captcha-subscribe-container">
                                {(form?.subscribe_text || form?.subscribe_extra_text)
                                  ? (
                                    <div className="subscribe-container">
                                      <div className="checkbox-container">
                                        <Checkbox
                                          title=""
                                          onClick={() => {
                                            setSubscribe(!subscribe);
                                          }}
                                        />
                                      </div>
                                      <div className="content-checkbox-container">
                                        <div className="bold-text">
                                          {convertHtmlToReact(form?.subscribe_text)}
                                        </div>
                                        {convertHtmlToReact(form?.subscribe_extra_text)}
                                      </div>
                                    </div>
                                  ) : null }
                                <div className="captcha-container">
                                  <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="normal"
                                    sitekey={process.env.REACT_APP_CAMPAIGN_KEY}
                                    onChange={recaptchaClicked}
                                  />
                                </div>
                              </div>
                              <Button btnSytle="campaign-button" style={{ backgroundColor: highlight_text_colour, color: banner_text_colour }} click={() => handleMessage()} disabled={!formIsValid()}>
                                {form?.submit_text}
                              </Button>
                            </>
                          )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    )
  };

  return (
    <>
      {
        width > 1024
        ?
          (
            <div className="banner-component-container" tabIndex="0" style={{
              backgroundImage: `url(${processWagtailImage(
                image
              )})`,
            }}>
              <div className="left-content">
                <div className="main-text" style={{color: banner_text_colour}} tabIndex="0">
                  {convertHtmlToReact(main_text)}
                </div>
                <div className="secondary-text" style={{color: banner_text_colour}} tabIndex="0">
                  {convertHtmlToReact(secondary_text)}
                </div>
              </div>
              <div className="right-content">
                {formContent()}
              </div>
            </div>
          )
        :
        width > 768 && width <= 1024
        ?
          (
            <>
              <div className="banner-component-container" tabIndex="0" style={{
                backgroundImage: `url(${processWagtailImage(
                  image
                )})`,
              }}>
                <div className="left-content">
                  <div className="main-text" style={{color: banner_text_colour}}>
                    {convertHtmlToReact(main_text)}
                  </div>
                  <div className="secondary-text" style={{color: banner_text_colour}}>
                    {convertHtmlToReact(secondary_text)}
                  </div>
                </div>
              </div>
              <div className="right-content">
                {formContent()}
              </div>
            </>
          )
        : (
            <>
              <div className="banner-component-container" tabIndex="0" style={{
                backgroundImage: `url(${processWagtailImage(
                  image
                )})`,
              }}>
                <div className="left-content">
                  <div className="main-text" style={{color: banner_text_colour}}>
                    {convertHtmlToReact(main_text)}
                  </div>
                  <div className="secondary-text" style={{color: banner_text_colour}}>
                    {convertHtmlToReact(secondary_text)}
                  </div>
                </div>
              </div>
              <div className="right-content">
                {formContent()}
              </div>
            </>
          )
      }
    </>
  );
};

export default BannerComponent;